<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">Gezilecek Yerler
          <span class="d-block text-muted pt-2 font-size-sm">Toplam {{ attractions.length }} adet gezilecek yer bulunmakta</span>
        </h3>
      </div>
    </div>
    <div class="card-body">
      <b-table
        class="table table-head-custom table-head-bg table-borderless table-vertical-center"
        thead-class="d-none"
        show-empty
        empty-text="Listelenecek herhangi bir gezilecek yer bulunamadı!"
        stacked="lg"
        :items="attractions"
        :fields="attractionDataTableFields"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template #cell(attraction)="data">
          <div class="py-2 pl-0">
            <div class="d-flex align-items-center">
              <div class="symbol symbol-70 symbol-2by3 flex-shrink-0 mr-4">
                <div class="symbol-label" :style="{ backgroundImage: `url(${data.item.thumbnail})` }"></div>
              </div>
              <div>
                <router-link
                  :to="{ name: 'show-attraction', params: { id: data.item.id }}"
                  v-slot="{ href }"
                >
                  <a :href="href" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ data.item.name }}</a>
                </router-link>
                <span class="text-muted font-weight-bold d-block">{{ data.item.numberOfExperiences }} Adet Tur & Akivite</span>
              </div>
            </div>
          </div>
        </template>
        <template #cell(status)="data">
          <span class="label label-lg label-light-success label-inline" v-if="data.item.status == 1">Yayında</span>
          <span class="label label-lg label-light-warning label-inline" v-if="data.item.status == 0">Yayında değil</span>
        </template>
        <template #cell(actions)="data">
          <router-link
            :to="{ name: 'show-attraction', params: { id: data.item.id }}"
            v-slot="{ href }"
          >
          <a :href="href" class="btn btn-icon btn-light btn-hover-primary btn-sm">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="media/svg/icons/Navigation/Arrow-right.svg" />
            </span>
          </a>
          </router-link>
        </template>
      </b-table>
      <b-col sm="7" md="6" class="my-1 pl-0" v-if="totalRows > perPage">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
          size="md"
        >
        </b-pagination>
      </b-col>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: "DestinationAttractions",
  props: ["destinationId"],
  data() {
    return {
       attractions: [],
        attractionDataTableFields: [
          { key: 'attraction', label: 'Destinasyon', sortable: false, class: 'pl-0' },
          { key: 'status', label: 'Durum', sortable: false },
          { key: 'actions', label: '', sortable: false, class: 'text-right' },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5
    }
  },
  mounted() {
    this.getAttractions();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    getAttractions() {
      ApiService.get(`destination/getAttractions.req.php?id=${this.destinationId}`)
      .then(({ data }) => {
        this.attractions = data.attractions;
        this.totalRows = this.attractions.length;
      })
      .catch(() => {
        //console.log(response);
      });
    }
  }
}
</script>

<style>

</style>