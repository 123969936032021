<template>
  <div>
    <div class="row">
      <div class="col-xl-7">
        <div class="row">
          <div class="col-xl-6">
            <div class="card card-custom gutter-b" :class="'bg-'+status[destinationStatus].bg" style="height: 130px">
              <!--begin::Body-->
              <div class="card-body">
                <span class="svg-icon svg-icon-3x svg-icon-white ml-n2">
                  <inline-svg :src="'media/svg/icons/'+status[destinationStatus].icon" />
                  <!--end::Svg Icon-->
                </span>
                <div class="text-inverse-success font-weight-bolder font-size-h3 mt-3">{{ status[destinationStatus].text }}</div>
              </div>
              <!--end::Body-->
            </div>
            <div class="card card-custom bg-light gutter-b" style="height: 130px">
              <!--begin::Body-->
              <div class="card-body d-flex flex-column p-0" style="position: relative;">
                <!--begin::Stats-->
                <div class="flex-grow-1 card-spacer-x pt-6">
                  <div class="text-dark-50 font-weight-bold">Bu Şehirde</div>
                  <div class="font-weight-bolder font-size-h3">{{ numberOfAttractions }} Destinasyon</div>
                </div>
                <!--end::Stats-->
              <div class="resize-triggers"><div class="expand-trigger"><div style="width: 208px; height: 131px;"></div></div><div class="contract-trigger"></div></div></div>
              <!--end::Body-->
            </div>
          </div>
          <div class="col-xl-6">
            <div class="card card-custom bg-info gutter-b" style="height: 130px;">
              <!--begin::Body-->
              <div class="card-body d-flex flex-column p-0" style="position: relative;">
                <!--begin::Stats-->
                <div class="flex-grow-1 card-spacer-x pt-6">
                  <div class="text-inverse-info font-weight-bold">Toplam Satış</div>
                  <div class="text-inverse-danger font-weight-bolder font-size-h3">{{ totalSale }}</div>
                </div>
                <!--end::Stats-->                
              </div>
              <!--end::Body-->
            </div>
            <div class="card card-custom bg-light gutter-b" style="height: 130px">
              <!--begin::Body-->
              <div class="card-body d-flex flex-column p-0" style="position: relative;">
                <!--begin::Stats-->
                <div class="flex-grow-1 card-spacer-x pt-6">
                  <div class="text-dark-50 font-weight-bold">{{ destinationName }} Hareketli</div>
                  <div class="font-weight-bolder font-size-h3">{{ numberOfExperiences }} Etkinlik</div>
                </div>
                <!--end::Stats-->
              <!--end::Body-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-5">
        <div class="card card-custom bgi-no-repeat gutter-b card-stretch" style="background-color: #1B283F; background-position: 0 calc(10% + 0.5rem); background-size: 100% auto; background-image: url(media/svg/patterns/aare.svg)">
          <div class="card-body">
            <div class="p-4">
              <h3 class="text-white font-weight-bolder my-7">Gezilecek Yer Oluştur</h3>
              <p class="text-muted font-size-lg mb-7">{{ destinationName }} için yeni bir gezilecek yer tanımlayın
              </p>
              <a href="#" class="btn btn-danger font-weight-bold px-6 py-3">Oluştur</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <MostPopularExperiences :destination-id="destinationId"></MostPopularExperiences>
      </div>
      <div class="col-xl-6">
        <BestSellerExperiences :destination-id="destinationId"></BestSellerExperiences>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <LastSixMonthSales :destination-id="destinationId" :destination-name="destinationName"></LastSixMonthSales>
      </div>
    </div>
    
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Swal from "sweetalert2";
import BestSellerExperiences from "@/view/pages/destination/components/BestSellerExperiences";
import MostPopularExperiences from "@/view/pages/destination/components/MostPopularExperiences";
import LastSixMonthSales from "@/view/pages/destination/components/charts/LastSixMonthSales";


export default {
  name: "DestinationOverview",
  components: {
    LastSixMonthSales,
    BestSellerExperiences,
    MostPopularExperiences
  },
  props: ["destinationId", "destinationName"],
  data () {
    return {
      status: {
        "N/A": {
          bg: "light",
          text: "N/A",
          icon: ""
        },
        "0": {
          bg: "warning",
          text: "Yayında Değil",
          icon: "Code/Warning-1-circle.svg"
        },
        "1": {
          bg: "success",
          text: "Yayında",
          icon: "Devices/LTE2.svg"
        }
      },
      destinationStatus: 'N/A',
      totalSale: 'N/A',
      numberOfAttractions: 0,
      numberOfExperiences: 0
    }
  },
  mounted() {
    this.getOverview();
  },
  methods: {
    getOverview() {
      ApiService.get(`destination/getOverview.req.php?id=${this.destinationId}`)
      .then(({ data }) => {
        this.destinationStatus = data.stats.status;
        this.totalSale = data.stats.totalSale; 
        this.numberOfAttractions = data.stats.numberOfAttractions;
        this.numberOfExperiences = data.stats.numberOfExperiences;
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    }
  }
}
</script>