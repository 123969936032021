<template>
  <div>
    <div class="d-flex flex-row align-items-center justify-content-between mb-8">
      <h2 class="font-weight-bold my-2 mr-5">
        {{ name }}
      </h2>
      <div class="d-flex flex-row align-items-center">
        <router-link
        :to="{ name: 'destinations'}"
        v-slot="{ href }"
      >
        <a :href="href" class="btn btn-light-primary btn-fixed-height font-weight-bold px-2 px-lg-5 mr-2">
          <span class="d-none d-md-inline">Destinasyonlar</span>
        </a>
      </router-link>
      <router-link
        :to="{ name: 'create-destination'}"
        v-slot="{ href }"
      >
        <a :href="href" class="btn btn-primary btn-fixed-height font-weight-bold px-2 px-lg-5">
          <span class="svg-icon">
            <inline-svg src="media/svg/icons/Code/Plus.svg" />
          </span>
          <span class="d-none d-md-inline">Destinasyon Ekle</span>
        </a>
      </router-link>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
        id="kt_profile_aside"
      >
        <div class="card card-custom card-stretch">
          <div class="card-body pt-8">
            <!--begin::Header-->
            <div class="d-flex flex-column flex-center">
              <!--begin::Image-->
              <div class="bgi-no-repeat bgi-position-center bgi-size-cover rounded min-h-180px w-100" :style="{ backgroundImage: `url(${thumbnail})` }"></div>
              <!--end::Image-->
              <!--begin::Title-->
              <a href="#" class="card-title font-weight-bolder text-dark-75 text-hover-primary font-size-h4 m-0 pt-7 pb-1">{{ name }}</a>
              <!--end::Title-->
              <!--begin::Text-->
              <div class="font-weight-bold text-dark-50 font-size-sm pb-7">Türkiye</div>
              <!--end::Text-->
            </div>
            <!--end::User-->
            <!--begin::Nav-->
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
              role="tablist"
            >
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4 active"
                  @click="setActiveTab"
                  href="javascript:;"
                  data-tab="0"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/Design/Layers.svg" />
                    </span>
                  </span>
                  <span class="navi-text font-size-h6">Genel Bakış</span>
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  href="javascript:;"
                  data-tab="1"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg
                        src="media/svg/icons/Map/Marker1.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text font-size-h6">Gezilecek Yerler</span>
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  href="javascript:;"
                  data-tab="2"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/Text/Article.svg" />
                    </span>
                  </span>
                  <span class="navi-text font-size-h6">Bilgiler</span>
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  href="javascript:;"
                  data-tab="3"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg src="media/svg/icons/General/Settings-2.svg" />
                    </span>
                  </span>
                  <span class="navi-text font-size-h6">Ayarlar</span>
                </a>
              </div>
              <div class="navi-item mb-2">
                <a
                  class="navi-link py-4"
                  @click="setActiveTab"
                  href="javascript:;"
                  data-tab="4"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="false"
                >
                  <span class="navi-icon mr-2">
                    <span class="svg-icon">
                      <inline-svg
                        src="media/svg/icons/Design/Image.svg"
                      />
                    </span>
                  </span>
                  <span class="navi-text font-size-h6">Fotoğrafı Değiştir</span>
                </a>
              </div>
            </div>
            <!--end::Nav-->
          </div>
        </div>
      </div>

      <!--begin::Content-->
      <div class="flex-row-fluid ml-lg-8">
        <b-tabs class="hide-tabs" v-model="tabIndex" lazy>
          <b-tab active>
            <DestinationOverview :destination-id="destinationId" :destination-name="name"></DestinationOverview>
          </b-tab>

          <b-tab>
            <DestinationAttractions :destination-id="destinationId"></DestinationAttractions>
          </b-tab>
          
          <b-tab>
            <DestinationDetails :destination-id="destinationId"></DestinationDetails>
          </b-tab>

          <b-tab>
            <DestinationSettings :destination-id="destinationId"></DestinationSettings>
          </b-tab>

          <b-tab>
            <DestinationImage :destination-id="destinationId"></DestinationImage>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from '@/core/services/api.service';
import DestinationOverview from "@/view/pages/destination/components/DestinationOverview";
import DestinationDetails from "@/view/pages/destination/components/DestinationDetails";
import DestinationSettings from "@/view/pages/destination/components/DestinationSettings";
import DestinationImage from "@/view/pages/destination/components/DestinationImage";
import DestinationAttractions from "@/view/pages/destination/components/DestinationAttractions";
import Swal from "sweetalert2";

export default {
  name: "DestinationProfile",
  components: {
    DestinationOverview,
    DestinationDetails,
    DestinationSettings,
    DestinationImage,
    DestinationAttractions
  },
  data () {
    return {
      tabIndex: -1,
      destinationId: this.$route.params.id,
      name: '',
      thumbnail: ''
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Destination" }]);

    this.getBriefDetails();
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },

    getBriefDetails() {
      ApiService.get(`destination/getBriefDetails.req.php?id=${this.destinationId}`)
      .then(({ data }) => {
        this.name = data.name;
        this.thumbnail = data.thumbnail;
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    }
  }
};
</script>