<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bold text-dark">
          Destinasyon Ayarları
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Destinasyon ayarlarını değiştirin</span
        >
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        
        <div class="d-flex justify-content-center">
          <b-alert class="col-xl-6 mb-8" :show="alert.dismissCountDown" :variant="alert.variant" dismissible fade @dismissed="dismissAlert()" @dismiss-count-down="countDownChanged">{{ alert.message }}</b-alert>
        </div>

        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Yayınlanma:</h5>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label"
            >Yayınlanma durumu</label
          >
          <div class="col-lg-9 col-xl-6" v-if="status == 1">
            <button
              type="button"
              class="btn btn-light-warning font-weight-bold btn-sm"
              @click="setStatus(0)"
            >
              Destinasyonu Yayından Kaldır
            </button>
            <p class="form-text text-muted pt-2">
              Destinasyonu yayından kaldırmanız durumunda bu destinasondan hareket eden tur ve aktivitelerde yayından kaldırılacaktır.
              <a href="javascript:;" v-b-modal.modal-1 class="text-primary font-weight-bold">Daha fazla</a>.
            </p>
          </div>
          <div class="col-lg-9 col-xl-6" v-if="status == 0">
            <button
              type="button"
              class="btn btn-light-success font-weight-bold btn-sm"
              @click="setStatus(1)"
            >
              Destinasyonu Yayına Al
            </button>
            <p class="form-text text-muted pt-2">
              Destinasyonu yayına almanız durumunda bu destinasondan hareket eden tur ve aktivitelerde yayından yayına alınacaktır.
              <a href="javascript:;" v-b-modal.modal-2 class="text-primary font-weight-bold">Daha fazla</a>.
            </p>
          </div>
        </div>
        
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->

    <!--begin::Modal-->
    <b-modal id="modal-1" title="Yayından Kaldırma" ok-only ok-title="Tamam">
      <h5>Destinasyonu yayından kaldırmanız durumunda</h5>
      <ul class="pl-8 pt-4">
        <li class="pl-2">Bu destinasyondan hareket eden <strong>tur, aktivite ve transferler</strong></li>
        <li class="pl-2">Bu destinasyonda bulunan <strong>gezilecek yerler</strong></li>
        <li class="pl-2">Bu destinasyonda bulunan <strong>buluşma noktaları</strong> yayından kaldırılır.</li>
      </ul>
      <p>Destinasyonu yayına aldığınızda yukarıda belirtilen ayarlar terardan yayına alınmış olur.</p>
    </b-modal>

     <b-modal id="modal-2" title="Yayına Alma" ok-only ok-title="Tamam">
      <h5>Destinasyonu yayına almanız durumunda</h5>
      <ul class="pl-8 pt-4">
        <li class="pl-2">Bu destinasyondan hareket eden <strong>tur, aktivite ve transferler</strong> (eğer yayında ise)</li>
        <li class="pl-2">Bu destinasyonda bulunan <strong>gezilecek yerler</strong> (eğer yayında ise)</li>
        <li class="pl-2">Bu destinasyonda bulunan <strong>buluşma noktaları</strong> (eğer yayında ise) yayına alınır.</li>
      </ul>
      <p>Destinasyonu yayından kaldırdığınızda yukarıda belirtilen ayarlar terardan yayından kaldırılmış olur.</p>
    </b-modal>
    <!--end::Modal-->

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Swal from "sweetalert2";

export default {
  name: 'DestinationSettings',
  props: ["destinationId"],
  data() {
    return {
      status: null,
      alert: {
        dismissCountDown: 0,
        variant: 'secondary',
        message: ''

      }
    }
  },
  methods: {
    // Get Status
    getStatus() {
      ApiService.get(`destination/getStatus.req.php?id=${this.destinationId}`)
      .then(({ data }) => {
        this.status = data.destinationStatus;
      })
      .catch(({ response }) => {
        this.status = null
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    },

    // Set Status
    setStatus(status) {
      const data = {
        status,
        id: this.destinationId,
      };
      ApiService.post('destination/setStatus.req.php', data)
      .then(() => {
        this.getStatus();
        this.showAlert('success', {'0': 'Destinasyon yayından kaldırıldı!', '1': 'Destinasyon yayına alındı!'}[status]);
      })
      .catch(({ response }) => {
        this.showAlert('danger', response.data.errorMessage);
      });
    },

    // Show Alert
    showAlert(variant, message) {
      this.alert.variant = variant;
      this.alert.message = message;
      this.alert.dismissCountDown = 5;
    },

    // Dismiss Alert
    dismissAlert() {
      this.alert.dismissCountDown = 0;
      this.alert.message = '';
      this.alert.variant = 'secondary';
    },

    // Alert count down changed
    countDownChanged(dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown;
    },
  },
  mounted() {
    this.getStatus();
  }
}
</script>

<style>

</style>