<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bold text-dark">
          Fotoğrafları Güncelle
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Destinasyon fotoğraflarını değiştirin</span
        >
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">

        <div class="row">
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Yatay Fotoğraf:</h5>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <div class="col-xl-6 col-lg-6">
            <div class="card card-custom overlay">
              <div class="card-body p-0">
                <div class="overlay-wrapper">
                  <img :src="horizontalImage.src" alt="" class="w-100 rounded"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-6">
            <div class="d-flex flex-column justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-8">
                <div class="symbol symbol-70 flex-shrink-0 mr-4">
                  <div class="symbol-label">
                    <span class="text-dark-65 font-weight-bolder">500 KB</span>
                  </div>
                </div>
                <div class="symbol symbol-70 flex-shrink-0 mr-4">
                  <div class="symbol-label">
                    <div class="d-flex flex-column align-items-center">
                      <span class="text-dark-65 font-weight-light">Width</span>
                      <span class="text-dark-65 font-weight-bolder">1920 px</span>
                    </div>
                  </div>
                </div>
                <div class="symbol symbol-70 flex-shrink-0 mr-4">
                  <div class="symbol-label">
                    <div class="d-flex flex-column align-items-center">
                      <span class="text-dark-65 font-weight-light">Height</span>
                      <span class="text-dark-65 font-weight-bolder">1080 px</span>
                    </div>
                  </div>
                </div>
                <div class="symbol symbol-70 flex-shrink-0 mr-4">
                  <div class="symbol-label">
                    <span class="svg-icon svg-icon-3x svg-icon-dark-65"><inline-svg src="media/svg/icons/Devices/Display3.svg" /></span>
                  </div>
                </div>
                <div class="symbol symbol-70 flex-shrink-0">
                  <div class="symbol-label">
                    <span class="svg-icon svg-icon-3x svg-icon-dark-65"><inline-svg src="media/svg/icons/Devices/Laptop.svg" /></span>
                  </div>
                </div>
              </div>
              <file-pond
                name="horizontal"
                allow-multiple="false" 
                accepted-file-types="image/jpeg, image/png, image/jpg"
                max-files="1" 
                maxFileSize="500KB"
                imageValidateSizeMinWidth="1920"
                imageValidateSizeMaxWidth="1920"
                imageValidateSizeMinHeight="1080"
                imageValidateSizeMaxHeight="1080"
                :server="{ process }"
              />
            </div>            
          </div>
        </div>

        <div class="separator separator-dashed my-8"></div>

        <div class="row">
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Dikey Fotoğraf:</h5>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-xl-6 col-lg-6">
            <div class="card card-custom overlay" style="box-shadow: none;">
              <div class="card-body p-0">
                <div class="overlay-wrapper">
                  <img :src="verticalImage.src" alt="" class="w-50 rounded"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-6">
            <div class="d-flex flex-column justify-content-between">
              <div class="d-flex align-items-center mb-8">
                <div class="symbol symbol-70 flex-shrink-0 mr-4">
                  <div class="symbol-label">
                    <span class="text-dark-65 font-weight-bolder">100 KB</span>
                  </div>
                </div>
                <div class="symbol symbol-70 flex-shrink-0 mr-4">
                  <div class="symbol-label">
                    <div class="d-flex flex-column align-items-center">
                      <span class="text-dark-65 font-weight-light">Width</span>
                      <span class="text-dark-65 font-weight-bolder">1080 px</span>
                    </div>
                  </div>
                </div>
                <div class="symbol symbol-70 flex-shrink-0 mr-4">
                  <div class="symbol-label">
                    <div class="d-flex flex-column align-items-center">
                      <span class="text-dark-65 font-weight-light">Height</span>
                      <span class="text-dark-65 font-weight-bolder">1920 px</span>
                    </div>
                  </div>
                </div>
                <div class="symbol symbol-70 flex-shrink-0">
                  <div class="symbol-label">
                    <span class="svg-icon svg-icon-3x svg-icon-dark-65"><inline-svg src="media/svg/icons/Devices/iPhone-X.svg" /></span>
                  </div>
                </div>
              </div>
              <file-pond 
                name="vertical"
                allow-multiple="false" 
                accepted-file-types="image/jpeg, image/png, image/jpg"
                max-files="1" 
                maxFileSize="100KB"
                imageValidateSizeMinWidth="1080"
                imageValidateSizeMaxWidth="1080"
                imageValidateSizeMinHeight="1920"
                imageValidateSizeMaxHeight="1920"
                :server="{ process }"
              />
            </div>            
          </div>
        </div>
        
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Swal from "sweetalert2";
import JwtService from '@/core/services/jwt.service';

import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm.js';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size/dist/filepond-plugin-image-validate-size.esm.js';
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImageValidateSize);

export default {
  components: {
    FilePond
  },
  props: ["destinationId"],
  data() {
    return {
      horizontalImage: {
        src: '',
      },
      verticalImage: {
        src: '',
      }
    }
  },
  methods: {
    // Get Destination Images - Main Image, Thumbnail -
    getImages() {
       ApiService.get(`destination/getImages.req.php?id=${this.destinationId}`)
      .then(({ data }) => {
        this.horizontalImage.src = data.mainImage;
        this.verticalImage.src = data.thumbnail;
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.error,
          text: response.data.errorMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    },

    process (fieldName, file, metadata, load, error, progress, abort) {
      const formData = new FormData();
      formData.append('id', this.destinationId);
      formData.append('image', file, file.name);

      const request = new XMLHttpRequest();
      const req = {
        "horizontal": "https://api.zenofontours.com/admin/v1/destination/setMainImage.req.php",
        "vertical": "https://api.zenofontours.com/admin/v1/destination/setThumbnail.req.php"
      }
      request.open("POST", req[fieldName]);
      request.setRequestHeader('Authorization', JwtService.getToken());

      request.upload.onprogress = e => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      const self = this;
      request.onload = function() {
        if (request.status >= 200 && request.status < 300) {
          self.getImages();
          load();
        } else {
          // Can call the error method if something is wrong, should exit after
          error();
        }
      };

      request.send(formData);

      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();

          // Let FilePond know the request has been cancelled
          abort();
        }
      }; 


    }
  },
  mounted() {
    this.getImages();
  } 
}
</script>

<style scoped>
.filepond--panel-root {
  background-color: #F3F6F9 !important;
}
</style>